import React from 'react'
import { graphql } from 'gatsby'
import { getPostsFromGraphQL } from "../utils";
import { Layout, ChartsGrid, HeadlinePanel } from "../components";
import withLocation from "../components/Common/withLocation";

const IndexPage = ({pageContext, data, search}) => {
  const {sortBy, sortOrder} = search;
  const sort={sortBy, sortOrder};
  const {showVariations} = pageContext;
  const posts = getPostsFromGraphQL(data, showVariations, sort);
  return (
    <Layout type="full">
      <HeadlinePanel includeVariations={showVariations} />
      <ChartsGrid posts={posts} isExpanded={showVariations} />
    </Layout>
  )
}

export default withLocation(IndexPage);

export const postsQuery = graphql`
  query PostsQuery {
    ...postList
  }
`;
